import React, { useState } from 'react'
import Slider from 'react-slick'
import { PreviewImage } from '../../../../components/PreviewImage'
import './index.sass'

export const FirstOfBaseball = () => {
  const [isPreviewImage, setIsPreviewImage] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const settings = {
    className: 'center',
    infinite: true,
    centerPadding: '50px',
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    arrows: true,
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  }
  const handleImageClick = (imageUrl: string) => {
    setIsPreviewImage(!isPreviewImage)
    setImageUrl(imageUrl)
  }
  return (
    <div className="first-of-baseball">
      <img src="/images/osamu-chris/first-of-baseball.jpeg" alt="" className="mb-2" />

      <div className="detail">
        <p className="title">FIST OF BASEBALL</p>
        フィストオブベースボール（以下、FOB）は、ロックリーグ、ペーパーリーグ、シザーズリーグの3リーグからなり、惑星シンシア（英語:
        Cynthia）に本拠地を置く29球団と惑星ヒュー（英語: Hugh）に本拠地を置く1球団の全30球団から構成されている女子リーグである。
        <br />
        <br />
        試合形式は、レギュラーシーズンとポストシーズンで構成され、最終的に各リーグの優勝チームと敗者復活戦を勝ち抜いた1チームの計4チームで優勝決定戦を行いワールドチャンピオンを決定する。レギュラーシーズンは4月から9月にかけて各チームが試合を行いリーグ優勝を争う。10月からポストシーズンがトーナメント形式で行われ、勝者がワールドチャンピオンとなる。
        <br />
        <br />
        毎年各チームに入団してくる40人のルーキーたちをトレーディングカード化したものが「FOBトレーディングカード」である。
        <br />
        <br />
        Created by Japanese Contemporary Artist, CHRIS, the FIST OF BASEBALL (FOB) is a women’s league consisting of three leagues: the Rock League,
        the Paper League, and the Scissors League. It is a women’s league made up of 30 teams: 29 teams located on planet Cynthia and one team located
        on planet Hugh. The format consists of regular season and post-season games, with the final four teams consisting of the winners of each
        league, and one team that survives the elimination round.
        <br />
        <br />
        The four teams go into the finals game, where they play for the World Championship. In the regular season, teams play games from April to
        September to compete for the league championship. The post-season begins in October with a tournament format, and the winner is crownded World
        Champion.
        <br />
        <br />
        The 40 rookies who join each team each year are made into trading cards called “FOB Trading Cards”.
        <div className="exhibition-content">
          <div className="block left">
            <div className="mb-2 slider-desk">
              <Slider {...settings}>
                <img src="/images/osamu-chris/slider-1.jpeg" alt="" onClick={() => handleImageClick('/images/osamu-chris/slider-1.jpeg')} />
                <img src="/images/osamu-chris/slider-2.jpeg" alt="" onClick={() => handleImageClick('/images/osamu-chris/slider-2.jpeg')} />
                <img src="/images/osamu-chris/slider-3.jpeg" alt="" onClick={() => handleImageClick('/images/osamu-chris/slider-3.jpeg')} />
              </Slider>
            </div>
            <p>
              会期：8/26-10/1 ※プレビューは前日8/25 <br /> 場所：〒140-0002 東京都品川区東品川1-32-8 Terrada Art ComplexⅡ 1F
              <br />
              <br />
              開廊時間：
              <br /> 火, 水, 木, 土 : 12:00 - 18:00 <br />金 : 12:00 - 20:00 <br /> 休廊 : 日・月・祝日
              <br />
              <br />
              August 26 ~ October 1 (Preview on August 25) <br /> TERRADA ART COMPLEX II 1F 1-32-8 Higashi-Shinagawa, Shinagawa-ku, Tokyo, 140 0002
              JAPAN
              <br />
              <br />
              Open Hours
              <br /> Tue, Wed, Thu, Sat : 12:00 - 18:00 <br /> Fri : 12:00 - 20:00 <br /> Closed on Sundays, Mondays and Public Holidays
            </p>
            <div className="google-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.3222776892153!2d139.74412555125187!3d35.61978078011167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bf9aab001b9%3A0x45effedf81ac4476!2sTERRADA%20ART%20COMPLEX%20II!5e0!3m2!1sja!2s!4v1661392554940!5m2!1sja!2s"
                lang="ja"
                width="100%"
                height="250"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <div className="block right">
            {/* <img src="/images/osamu-chris/exhibition-show-mobile.jpeg" alt="" className="mobile-img mb-2" /> */}
            <div className="mobile-img mb-2">
              <Slider {...settings}>
                <img src="/images/osamu-chris/slider-1.jpeg" alt="" />
                <img src="/images/osamu-chris/slider-2.jpeg" alt="" />
                <img src="/images/osamu-chris/slider-3.jpeg" alt="" />
              </Slider>
            </div>
            <p className="title">“FIST OF BASEBALL” EXHIBITION AT YUKIKOMIZUTANI</p>
            <p>
              <a href="https://yukikomizutani.com/" target="_blank" className="link-black-underline" rel="noopener noreferrer">
                YUKIKOMIZUTANI
              </a>
              では、8月26日から10月1日までCHRIS 個展 「FIST OF
              BASEBALL」を開催いたします。本展覧会では、CHRISによる新作のペインティング作品を発表します。
              <br />
              <br />
              膨大な数の雑誌やホビーを収集するコレクターとしての一面を持つCHRISは、作家活動においても、そのコレクションを表現のツールとして用いてきました。本展では、自身の活動において大きな意味を持つ「収集」という行為を再考し、そこに隠された創造性を表出させます。
              <br />
              <br />
              <a href="https://yukikomizutani.com/" target="_blank" className="link-black-underline" rel="noopener noreferrer">
                YUKIKOMIZUTANI
              </a>{' '}
              is pleased to present CHRIS’s solo exhibition “FIST OF BASEBALL” from August 25 to October 1. With a vast array of magazines and hobby
              collectibles, CHRIS has always looked to his collection as a source of creativity. Celebrating his 10th year as an artist, the
              exhibition will feature works that re-examine the act of collecting, which has played a central role in his understanding and creation
              of art works. <br />
              <br />
              In this exhibition, titled “FIST OF BASEBALL,” a baseball league in a fictional universe is materialized in the form of trading cards.
              In collecting trading cards, a collector becomes engaged in a unique process of world-building. In a time when we are seeing
              unprecedented levels of individuality and creativity in society, the trading card can be seen as an item representative of the
              collector’s culture today. Throughout the exhibition, CHRIS makes an iteration of the trading card where the act of collecting leads to
              world-building, hinting of potential creativity in the act of collecting.
            </p>
          </div>
        </div>
      </div>
      {isPreviewImage && <PreviewImage imageUrl={imageUrl} onCloseModal={() => setIsPreviewImage(false)} />}
    </div>
  )
}
