import { Middleware } from 'redux'
import { notification } from 'antd'
import { LOGIN_REJECTED } from '../common/action'
import { NOTIFICATION_MESSAGE } from '../../configs'

const authorization: Middleware = () => next => action => {
  const payload = action.payload

  if (payload?.error?.status === 401 || payload?.error?.message === 'Unauthorized') {
    notification.error({
      message: 'Unauthorized',
      description: NOTIFICATION_MESSAGE.auth.unauthorized
    })

    return next({
      type: LOGIN_REJECTED
    })
  }
  next(action)
}

export default authorization
