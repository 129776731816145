import React from 'react'
import { Collapse } from 'antd'

import './index.sass'

export const FAQ = () => {
  const { Panel } = Collapse

  const renderExpandIcon = (expanded: any) => {
    const { isActive } = expanded
    return isActive ? (
      <img src="/images/osamu-chris/icons/dropdown-open.svg" alt="" />
    ) : (
      <img src="/images/osamu-chris/icons/dropdown-close.svg" alt="" />
    )
  }

  return (
    <div className="faq">
      <div className="title mb-1">FAQs</div>
      <Collapse bordered={false} expandIcon={expanded => renderExpandIcon(expanded)} expandIconPosition="right" ghost>
        <Panel header="Wallet（ウォレット）とは？ What is a wallet?" key="1">
          <br />
          デジタルウォレットは、あなたの暗号通貨を管理し、またEthereum（イーサリアム）のようなブロックチェーン技術と対話できるようにするソフトウェアです。ウォレットアドレスはイーサリアムブロックチェーン上のあなたのIDであり、あなたのすべての作成物にデジタル署名するために使用されます。個人用ウォレットを作成する必要がある場合は、MetaMaskの使用をお勧めします。{' '}
          <br />
          <br />
          まず初めに、
          <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer" className="link-black-underline">
            Metamask
          </a>
          公式ページにアクセスしてダウンロードを行いましょう。ここでは、Google
          Chromeをベースにお話ししていきます。このウォレットを作成すると、ウォレット１つずつにアドレスが付与されます。
          <br />
          <br />
          <span className="fw-700">ワンポイントアドバイス！</span>
          <br />
          ダウンロードが完了すると、12個の英単語で構成される「シークレットリカバリーフレーズ」というものが付与されます。こちらはパスワードの代わりとなるものなので、保存しておきましょう。自分の作成したパスワードを忘れた際に、こちらのフレーズが必要になるので、大切に保管してください。おすすめの保管方法は、1.紙に書く
          2.スクリーンショットを撮って印刷 3. ケータイやパソコンのメモ帳に保存 (セキュリティ上あまりよろしくはない)
          などあります。紙媒体となると劣化してしまう恐れがあるので、湿気や光が当たらない場所で保管してください（金庫や簞笥)。 <br />
          <br />A digital wallet is software that manages your cryptocurrencies and also allows you to interact with blockchain technologies like
          Ethereum. Your wallet address is your ID on the Ethereum blockchain and is used to digitally sign all of your creations. If you need to
          create a personal wallet, we recommend using{' '}
          <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer" className="link-black-underline">
            Metamask.
          </a>
        </Panel>
        <Panel header="イーサとは？ What is Ether?" key="2">
          <br />
          EtherまたはΞはEthereumとやりとりするための暗号通貨で、CHRISからアートNFTを購入する際に使用するものです。
          <br />
          <br />
          Step 1:{' '}
          <a href="https://coincheck.com/ja/lp" target="_blank" rel="noopener noreferrer" className="link-black-underline">
            Coincheck
          </a>{' '}
          などの取引所で無料口座開設をする
          <br />
          Step 2: 日本円を入金する
          <br />
          Step 3: イーサ(ETH)を購入する
          <br />
          Step 4: Metamaskを作成
          <br />
          Step 5: イーサをMetaMaskに送金する
          <br />
          <br />
          イーサリアムは、世界中の多くのコンピュータによって運用されているワールドコンピュータと言われています。ガス代とはイーサリアムの運営者への取引手数料です。
          <br />
          <br />
          Ether or Ξ is the cryptocurrency used to interact with Ethereum, and it’s what you’ll use to purchase the art NFT from CHRIS. Ethereum is
          considered a world computer, operated by many computers across the world. This means that interacting with it incurs a transaction fee
          called “gas” to compensate the operators of Ethereum.
        </Panel>
        <Panel header="オークションの入札方法？ How do I bid in this auction?" key="3">
          <br />
          オークションで入札された方全員に、2023年初頭（日付未定）に発売予定の「Fist of
          Baseball」コレクションへのホワイトリストを割り当てる予定です。入札は0.2 Ether
          単位で増加し、オークションに入札するためには、ウォレットに十分な資金があることが必要です。
          <br />
          <br />
          Everyone who bids in the auction will be allocated a White List to the Fist of Baseball collection due to release early 2023 (date TBD).
          Each bid will increase by 0.2 Ether increments, and in order to bid in the auction, the wallet will need to have sufficient funds to make
          the payment.
        </Panel>
      </Collapse>
    </div>
  )
}
