export const HDKN_CONTRACT_ABI = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'approved', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' }
    ],
    name: 'Approval',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'operator', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' }
    ],
    name: 'ApprovalForAll',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'newBidder', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'newAmount', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'oldBidder', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'oldAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'timestamp', type: 'uint256' }
    ],
    name: 'ItemBidded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      {
        components: [
          { internalType: 'bool', name: 'inited', type: 'bool' },
          {
            components: [
              { internalType: 'bool', name: 'buyable', type: 'bool' },
              { internalType: 'uint256', name: 'price', type: 'uint256' }
            ],
            internalType: 'struct HDKNTest.SaleInfo',
            name: 'saleInfo',
            type: 'tuple'
          },
          {
            components: [
              { internalType: 'bool', name: 'active', type: 'bool' },
              { internalType: 'bool', name: 'bidding', type: 'bool' },
              { internalType: 'uint64', name: 'startAt', type: 'uint64' },
              { internalType: 'uint64', name: 'endAt', type: 'uint64' },
              { internalType: 'uint64', name: 'windowTime', type: 'uint64' },
              { internalType: 'uint256', name: 'reservePrice', type: 'uint256' },
              { internalType: 'uint256', name: 'minimalBidGap', type: 'uint256' },
              { internalType: 'address', name: 'highestBidder', type: 'address' },
              { internalType: 'uint256', name: 'highestBid', type: 'uint256' }
            ],
            internalType: 'struct HDKNTest.AuctionInfo',
            name: 'auctionInfo',
            type: 'tuple'
          },
          { internalType: 'string', name: 'uri', type: 'string' }
        ],
        indexed: false,
        internalType: 'struct HDKNTest.TokenInfo',
        name: 'tokenInfo',
        type: 'tuple'
      }
    ],
    name: 'TokenModified',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' }
    ],
    name: 'Transfer',
    type: 'event'
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' }
    ],
    name: 'approve',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '', type: 'uint16' },
      { internalType: 'uint256', name: '', type: 'uint256' }
    ],
    name: 'auctionHistories',
    outputs: [
      { internalType: 'address', name: 'bidder', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'uint256', name: 'timestamp', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint16', name: '_tokenId', type: 'uint16' }],
    name: 'auctionWinnerWithdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  { inputs: [{ internalType: 'uint16', name: '_tokenId', type: 'uint16' }], name: 'bid', outputs: [], stateMutability: 'payable', type: 'function' },
  {
    inputs: [{ internalType: 'uint16', name: '_tokenId', type: 'uint16' }],
    name: 'bidderWithdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint16', name: '_tokenId', type: 'uint16' }],
    name: 'buyNow',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '_tokenId', type: 'uint16' },
      { internalType: 'bool', name: '_buyable', type: 'bool' },
      { internalType: 'uint256', name: '_price', type: 'uint256' },
      { internalType: 'bool', name: '_active', type: 'bool' },
      { internalType: 'uint64', name: '_startAt', type: 'uint64' },
      { internalType: 'uint64', name: '_endAt', type: 'uint64' },
      { internalType: 'uint64', name: '_windowTime', type: 'uint64' },
      { internalType: 'uint256', name: '_reservePrice', type: 'uint256' },
      { internalType: 'uint256', name: '_minimalBidGap', type: 'uint256' },
      { internalType: 'string', name: '_uri', type: 'string' }
    ],
    name: 'createToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '_tokenId', type: 'uint16' },
      { internalType: 'uint256', name: '_from', type: 'uint256' },
      { internalType: 'uint256', name: '_to', type: 'uint256' }
    ],
    name: 'getAuctionHistories',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'bidder', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'timestamp', type: 'uint256' }
        ],
        internalType: 'struct HDKNTest.AuctionHistory[]',
        name: 'items',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint16', name: '_tokenId', type: 'uint16' }],
    name: 'getAuctionHistoriesLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'operator', type: 'address' }
    ],
    name: 'isApprovedForAll',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  { inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
  { inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '', type: 'uint16' },
      { internalType: 'address', name: '', type: 'address' }
    ],
    name: 'refunds',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' }
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'bytes', name: 'data', type: 'bytes' }
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'operator', type: 'address' },
      { internalType: 'bool', name: 'approved', type: 'bool' }
    ],
    name: 'setApprovalForAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '_tokenId', type: 'uint16' },
      { internalType: 'uint64', name: '_reservePrice', type: 'uint64' },
      { internalType: 'uint64', name: '_minimalBidGap', type: 'uint64' }
    ],
    name: 'setAuctionBid',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '_tokenId', type: 'uint16' },
      { internalType: 'uint64', name: '_startAt', type: 'uint64' },
      { internalType: 'uint64', name: '_endAt', type: 'uint64' }
    ],
    name: 'setAuctionTime',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '_tokenId', type: 'uint16' },
      { internalType: 'uint64', name: '_windowTime', type: 'uint64' }
    ],
    name: 'setAuctionWindowTime',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '_tokenId', type: 'uint16' },
      { internalType: 'uint256', name: '_price', type: 'uint256' }
    ],
    name: 'setTokenPrice',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint16', name: '_tokenId', type: 'uint16' },
      { internalType: 'string', name: '_uri', type: 'string' }
    ],
    name: 'setTokenURI',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  { inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
  {
    inputs: [
      { internalType: 'uint16', name: '_tokenId', type: 'uint16' },
      { internalType: 'bool', name: '_buyable', type: 'bool' }
    ],
    name: 'toggleTokenBuyable',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_index', type: 'uint256' }],
    name: 'tokenByIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_owner', type: 'address' },
      { internalType: 'uint256', name: '_index', type: 'uint256' }
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    name: 'tokens',
    outputs: [
      { internalType: 'bool', name: 'inited', type: 'bool' },
      {
        components: [
          { internalType: 'bool', name: 'buyable', type: 'bool' },
          { internalType: 'uint256', name: 'price', type: 'uint256' }
        ],
        internalType: 'struct HDKNTest.SaleInfo',
        name: 'saleInfo',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'bool', name: 'active', type: 'bool' },
          { internalType: 'bool', name: 'bidding', type: 'bool' },
          { internalType: 'uint64', name: 'startAt', type: 'uint64' },
          { internalType: 'uint64', name: 'endAt', type: 'uint64' },
          { internalType: 'uint64', name: 'windowTime', type: 'uint64' },
          { internalType: 'uint256', name: 'reservePrice', type: 'uint256' },
          { internalType: 'uint256', name: 'minimalBidGap', type: 'uint256' },
          { internalType: 'address', name: 'highestBidder', type: 'address' },
          { internalType: 'uint256', name: 'highestBid', type: 'uint256' }
        ],
        internalType: 'struct HDKNTest.AuctionInfo',
        name: 'auctionInfo',
        type: 'tuple'
      },
      { internalType: 'string', name: 'uri', type: 'string' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  { inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' }
    ],
    name: 'transferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  { inputs: [], name: 'withdrawETH', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'uint16[]', name: '_tokenIds', type: 'uint16[]' }],
    name: 'withdrawNFT',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]
