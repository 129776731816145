import React from 'react'

export const MailChimpCustomForm = ({ status, message, placeholder, textSubmit, textSuccess, onValidated }: MailChimpCustomFormProps) => {
  let email: any
  const submit = () => {
    return (
      email &&
      onValidated({
        EMAIL: email.value
      })
    )
  }

  return (
    <>
      <div className="newsletter-box">
        <div className="form-input">
          <input ref={node => (email = node)} type="email" className="email-input" placeholder={placeholder} />
          <button className="btn-subscribe" onClick={submit}>
            <img src="/images/icons/arrow-right-circle.png" alt="" />
          </button>
        </div>
        {status === 'sending' && (
          <div className="sending message" style={{ color: 'black', marginTop: '5px' }}>
            Sending...
          </div>
        )}
        {status === 'error' && (
          <div className="error message" style={{ color: '#e11a1a', marginTop: '5px' }} dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <div
            className="success message"
            style={{ color: 'green', marginTop: '5px' }}
            dangerouslySetInnerHTML={{
              __html: textSuccess || message
            }}
          />
        )}
      </div>
    </>
  )
}

interface MailChimpCustomFormProps {
  status: any
  message: any
  placeholder: string
  textSubmit: string
  textSuccess: string
  // eslint-disable-next-line no-unused-vars
  onValidated: (formData: any) => void
}
