import React, { useRef } from 'react'
import { Modal } from 'antd'
import './index.sass'

export const PreviewImage = ({ imageUrl, onCloseModal }: PreviewImageProps) => {
  const refModal = useRef<any>()
  const lastDot = imageUrl.lastIndexOf('.')
  const mime = imageUrl.slice(lastDot)
  return (
    <>
      <Modal className="modal-preview-image" visible onCancel={onCloseModal} closeIcon={<img src="/images/icons/close-x.svg" ref={refModal} />}>
        <div className="md-content">
          <img src={imageUrl} alt="" />
        </div>
      </Modal>
    </>
  )
}

export interface PreviewImageProps {
  onCloseModal: () => void
  imageUrl: string
}
