import React from 'react'
import { Collapse } from 'antd'

import './index.sass'

export const PastExhibitions = () => {
  const { Panel } = Collapse

  const renderExpandIcon = (expanded: any) => {
    const { isActive } = expanded
    return isActive ? (
      <img src="/images/osamu-chris/icons/dropdown-open.svg" alt="" />
    ) : (
      <img src="/images/osamu-chris/icons/dropdown-close.svg" alt="" />
    )
  }

  return (
    <div className="past-exhibitions">
      <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={expanded => renderExpandIcon(expanded)} expandIconPosition="right" ghost>
        <Panel header="PAST EXHIBITIONS" key="1">
          <p className="fw-700">主な個展</p>
          2022年 YUKIKOMIZUTANI 「Fist of baseball」
          <br />
          2022年 Ricoh art gallery 「Take Me Out to the Ball Game」
          <br />
          2021年 Hi-NODE 「Love is a battlefield (It’s hard to see Love)」
          <br />
          2021年 un petit GARAGE 「ROOKIE」
          <br />
          2020年 SO1 gallery 「XXX」
          <br />
          2019年 伊勢丹新宿 「MOUT EXHIBITION 2」
          <br />
          2019年 un petit GARAGE 「ALTER EGO」
          <br />
          <br />
          <p className="fw-700">主なグループ展</p>
          2022年 Kiaf Seoul 2022
          <br />
          2022年 ART FAIR TOKYO 2022
          <br />
          2021年 KOMIYAMA TOKYO 「REAL TOKYO ART」
          <br />
          2021年 THE PLUG「SHIRAI展」
          <br />
          2021年 ART FAIR TOKYO 2021
          <br />
          2020年 KOMIYAMA TOKYO 「REAL TOKYO ART」
          <br />
          2020年 明治神宮「紫幹翠葉ー百年の杜のアート」
          <br />
          2020年 ART FAIR TOKYO 2021
          <br />
          2019年 ART FAIR TOKYO 2020
          <br />
          <br />
          <p className="fw-700">Selected Solo Exhibition</p>
          2022: YUKIKOMIZUTANI “Fist of baseball”
          <br />
          2022: Ricoh art gallery “Take Me Out to the Ball Game”
          <br />
          2021: Hi-NODE “Love is a battlefield (It’s hard to see Love)”
          <br />
          2021: un petit GARAGE “ROOKIE”
          <br />
          2020: SO1 gallery “XXX”
          <br />
          2019: Isetan Shinjuku “MOUT EXHIBITION 2”
          <br />
          2019: un petit GARAGE “ALTER EGO”
          <br />
          <br />
          <p className="fw-700">Selected Group Exhibition</p>
          2022: Kiaf Seoul 2022
          <br />
          2022: ART FAIR TOKYO 2022
          <br />
          2021: KOMIYAMA TOKYO “REAL TOKYO ART”
          <br />
          2021: THE PLUG “SHIRAI”
          <br />
          2021: ART FAIR TOKYO 2021
          <br />
          2020: KOMIYAMA TOKYO “REAL TOKYO ART”
          <br />
          2020: Meiji Jingu “Shikansuiyo - Art of the One Hundred Year Forest”
          <br />
          2020: ART FAIR TOKYO 2021
          <br />
          2019: ART FAIR TOKYO 2020
        </Panel>
      </Collapse>
    </div>
  )
}
