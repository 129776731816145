import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Outlet } from 'react-router-dom'

import './index.sass'

export const CommonLayout = () => {
  return (
    <>
      {/* <Header /> */}
      <div id="app-content">
        <Outlet />
      </div>
      {/* <Footer /> */}
    </>
  )
}
