import { IForgotPassword, ILoginData, IResetPasswordParams, ISignupData } from '../../types/Auth'
import { IForgotPasswordDto, IResetPasswordDto, ISignUpDto, ISignUpGetCode, IUserDto } from '../../types/User'
import { BaseRequest } from '../BaseRequest'

export class AuthRequest extends BaseRequest {
  async login(params: ILoginData): Promise<any> {
    const url = '/users/login'
    const data: any = await this.post(url, params)
    return data?.user || {}
  }

  async forgotPassword(params: IForgotPassword): Promise<IForgotPasswordDto> {
    const url = '/users/forgotpassword'
    const data: any = await this.post(url, params)
    return data
  }

  async resetPassword(params: IResetPasswordParams): Promise<IResetPasswordDto> {
    const url = '/users/resetpassword'
    const data: any = await this.post(url, params)
    return data
  }

  async getUserByJWTToken(): Promise<IUserDto> {
    const url = '/users/'
    const data: any = await this.get(url)
    return data?.user || {}
  }

  async logout(): Promise<any> {
    const url = '/users/logout'
    return this.post(url)
  }

  async signUp(params: ISignupData): Promise<ISignUpDto> {
    const url = '/users'
    const data: any = await this.post(url, params)
    return data?.user || {}
  }
}
