import { HDKN_CONTRACT_ABI } from '../contracts/OsamuChris/main'
import { QUERY_CONTRACT_ABI } from '../contracts/Stuffs/query'

export type Environments = {
  IS_PRODUCTION: boolean
  API_ROOT_URL: string

  REACT_APP_JWT_KEY: string
  AUTH: string
  REACT_APP_REFRESH_TOKEN: string
  PAGE_SIZE: number
  PAGE_INDEX: number
  SUPPORTED_CHAIN_IDS: number
  DEFAULT_NETWORK: string
  ETHEREUM_PROVIDER_URL: string

  REACT_APP_S3_URL: string
  HDKN_CONTRACT_ADDRESS: string
  HDKN_CONTRACT_ABI: any
  QUERY_CONTRACT_ADDRESS: string
  QUERY_CONTRACT_ABI: any
  METAMASK_DEEP_LINK: string
}

export const APP_ENVIRONMENTS: Environments = {
  IS_PRODUCTION: String(process.env.NODE_ENV) === 'production',
  API_ROOT_URL: String(process.env.REACT_APP_API_ROOT_URL || ''),
  REACT_APP_JWT_KEY: String(process.env.REACT_APP_JWT_KEY || 'BEARER_TOKEN'),
  AUTH: String(process.env.REACT_APP_AUTH || 'AUTH'),

  REACT_APP_REFRESH_TOKEN: String(process.env.REACT_APP_REACT_APP_REFRESH_TOKEN || 'REFRESH_TOKEN'),
  PAGE_SIZE: 30,
  PAGE_INDEX: 1,

  SUPPORTED_CHAIN_IDS: Number(process.env.REACT_APP_SUPPORTED_CHAIN_IDS || 1),
  DEFAULT_NETWORK: String(process.env.REACT_APP_DEFAULT_NETWORK || 'mainnet'),
  ETHEREUM_PROVIDER_URL: String(process.env.REACT_APP_ETHEREUM_PROVIDER_URL || 'https://mainnet.infura.io/v3/d6e76dfa7d814896b867f07a79ec0e7a'),

  REACT_APP_S3_URL: String(process.env.REACT_APP_S3_URL || ''),
  HDKN_CONTRACT_ADDRESS: String(process.env.REACT_APP_HDKN_CONTRACT_ADDRESS || '0x52323699203bFc77F7968451A68ffdAcF7fF6c4c'),
  HDKN_CONTRACT_ABI,
  QUERY_CONTRACT_ADDRESS: String(process.env.REACT_APP_QUERY_CONTRACT_ADDRESS || '0x042D7f992f6BBDb5211755C437061e2bCB97f9AD'),
  QUERY_CONTRACT_ABI,
  METAMASK_DEEP_LINK: String(process.env.REACT_APP_METAMASK_DEEP_LINK || 'https://metamask.app.link/dapp/hdkn.io')
}
