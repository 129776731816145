import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import './index.sass'
import { MailChimpCustomForm } from './MailChimpCustomForm'

const mailchimpURL = 'https://pellartech.us12.list-manage.com/subscribe/post?u=cc5be5bd03915949d7b529528&amp;id=caef277c73&amp;f_id=0029b7e0f0'

export const SubscribeFOB = () => {
  return (
    <div className="subscribe-fob">
      <h3 className="title text-center">Subscribe to Fist of Baseball!</h3>
      <p className="subtitle text-center">Fist of Baseballの最新情報をお届けします。</p>
      <MailchimpSubscribe
        url={mailchimpURL}
        render={({ subscribe, status, message }) => (
          <MailChimpCustomForm
            status={status}
            placeholder={'Email'}
            textSubmit="Submit"
            textSuccess="Your subscription to our list has been confirmed.                  Thank you for subscribing!
                  "
            message={message}
            onValidated={formData => {
              subscribe(formData)
            }}
          />
        )}
      />
    </div>
  )
}
