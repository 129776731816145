import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

import './index.sass'
import { APP_ENVIRONMENTS, NOTIFICATION_MESSAGE, SOCIAL_LINKS } from '../../../configs'
import { PastExhibitions } from './PastExhibitions'
import { FirstOfBaseball } from './FirstOfBaseball'
import { FAQ } from './FAQ'
import { useWeb3React } from '@web3-react/core'
import { connectWallet, formatAccountDisplay, formatTransactionHashDisplay, handleCopy } from '../../../utils/helpers'
import { injected } from '../../../connectors'
import { Contract, ethers } from 'ethers'
import { notification, Skeleton } from 'antd'
import { HDKNRequest, RequestEnum, RequestFactory } from '../../../requests'
import { ListBidModal } from './ListBidModal'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { CountDownTime } from '../../../components/shared/CountDownTime'
import CopyToClipboard from 'react-copy-to-clipboard'
import { SubscribeFOB } from './SubscribeFOB'

export const OsamuChris = () => {
  const hdknRequest = RequestFactory.getRequest<HDKNRequest>(RequestEnum.HDKNRequest)
  const { account, chainId, activate, active, error, library } = useWeb3React()
  const [minBidPrice, setMinBidPrice] = useState(0)
  const [bidPrice, setBidPrice] = useState(0)
  const [isOpenListBid, setIsOpenListBid] = useState(false)
  const [totalBidHistories, setTotalBidHistories] = useState(0)
  const [tokenInfo, setTokenInfo] = useState<any>({})
  const [intervalId, setIntervalId] = useState<any>(null)
  const [currentUnixTime, setCurrentUnixTime] = useState(moment.utc().unix())
  const [accountBalance, setAccountBalance] = useState(0)
  const [isBidding, setIsBidding] = useState(false)
  const [isBuying, setIsBuying] = useState(false)
  const [isAuctionEnded, setIsAuctionEnded] = useState(false)

  // Default auto connect network
  useEffect(() => {
    injected
      .isAuthorized()
      .then(isAuthorized => {
        if (isAuthorized && !active && !error) {
          activate(injected)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [activate, active, error])

  useEffect(() => {
    checkAccountBalance()
  }, [account, chainId])

  useEffect(() => {
    getTokenInfo()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUnixTime(moment.utc().unix())
    }, 1000)

    setIntervalId(interval)

    return intervalId && clearInterval(intervalId)
  }, [])

  const checkAccountBalance = async () => {
    if (!account) return
    const provider = ethers.getDefaultProvider(APP_ENVIRONMENTS.ETHEREUM_PROVIDER_URL)
    const balance = await provider.getBalance(account)
    const fixedDecimalBalance = Number(Number(ethers.utils.formatEther(balance)).toFixed(4))
    setAccountBalance(fixedDecimalBalance)
  }

  const getTokenInfo = async () => {
    try {
      const { token_info, total_bid_histories } = await hdknRequest.getTokenInfo(0)
      const highestBid = Math.max(token_info?.auction_info?.highest_bid?.value, token_info?.auction_info?.reserve_price?.value)
      const minimalBidGap = token_info?.auction_info?.minimal_bid_gap?.value
      const _bidPrice = Number(highestBid + minimalBidGap).toFixed(4)

      setTokenInfo(token_info)
      setBidPrice(Number(_bidPrice))
      setMinBidPrice(Number(_bidPrice))
      setTotalBidHistories(total_bid_histories)
    } catch {}
  }

  const contract = useMemo(() => {
    if (library) {
      return new Contract(APP_ENVIRONMENTS.HDKN_CONTRACT_ADDRESS, APP_ENVIRONMENTS.HDKN_CONTRACT_ABI, library.getSigner())
    }
  }, [library, chainId])

  const handleBid = async () => {
    if (!contract) return
    if (accountBalance < bidPrice) {
      return notification.error({
        message: 'Failed Transaction',
        description: 'Insufficient funds'
      })
    }

    let estimateGas

    try {
      setIsBidding(true)
      estimateGas = await contract.estimateGas.bid(0, {
        value: ethers.utils.parseEther(String(bidPrice))
      })

      const txn = await (
        await contract.bid(0, {
          value: ethers.utils.parseEther(String(bidPrice)),
          gasLimit: estimateGas.mul(150).div(100)
        })
      ).wait(1)
      getTokenInfo()
      setIsBidding(false)

      notification.success({
        message: 'Transaction Success',
        description: (
          <a href={`https://etherscan.io/tx/${txn.transactionHash}`} target="_blank" className="txn-link">
            Transaction Hash: <br />
            <span>{formatTransactionHashDisplay(txn?.transactionHash)}</span>
          </a>
        ),
        duration: 0
      })
    } catch (err: any) {
      showErrMessage(err)
      setIsBidding(false)
    }
  }

  const handleBuy = async () => {
    if (!contract) return
    if (accountBalance < tokenInfo?.sale_info?.price?.value) {
      return notification.error({
        message: 'Failed Transaction',
        description: 'Insufficient funds'
      })
    }
    let estimateGas

    try {
      setIsBuying(true)
      estimateGas = await contract.estimateGas.buyNow(0, {
        value: ethers.utils.parseEther(String(tokenInfo?.sale_info?.price?.value || 10))
      })

      const txn = await (
        await contract.buyNow(0, {
          value: ethers.utils.parseEther(String(tokenInfo?.sale_info?.price?.value || 10)),
          gasLimit: estimateGas.mul(150).div(100)
        })
      ).wait(1)
      getTokenInfo()
      setIsBuying(false)

      notification.success({
        message: 'Transaction Success',
        description: (
          <a href={`https://etherscan.io/tx/${txn.transactionHash}`} target="_blank" className="txn-link">
            Transaction Hash: <br />
            <span>{formatTransactionHashDisplay(txn?.transactionHash)}</span>
          </a>
        ),
        duration: 0
      })
    } catch (err: any) {
      showErrMessage(err)
      setIsBuying(false)
    }
  }

  const showErrMessage = (err: any) => {
    const errMsg = err.error ? err.error.message : err.message || NOTIFICATION_MESSAGE.metamask.unknown_error

    return notification.error({
      message: 'Failed Transaction',
      description: errMsg
    })
  }

  const renderAuctionInfo = () => {
    if (!isEmpty(tokenInfo)) {
      return (
        <div>
          <p className="fw-700">
            現在価格 Current Price: <br />
            <span className="fsz-18">{tokenInfo?.auction_info?.highest_bid?.value || 0.1} ETH</span>
          </p>
          {/* <p>
            入札された方にはホワイトリストが配られます。
            <br />
            White List will be given to everyone who bids.
          </p> */}

          <p>
            <span>終了予定:</span> {moment.unix(tokenInfo?.auction_info?.end_at).utcOffset('+09:00').format('YYYY 年 MM 月 DD, HH:mm')} <br />
            <span>Auction Ends:</span> {moment.unix(tokenInfo?.auction_info?.end_at).utcOffset('+09:00').format('DD MMM YYYY, HH:mm')} JST <br />
            <div className="d-flex">
              {!isAuctionEnded ? (
                <>
                  <span style={{ marginRight: '3px' }}>残り Finishing in: </span>
                  <CountDownTime endTime={tokenInfo?.auction_info?.end_at} counterEnd={() => setIsAuctionEnded(true)} />
                </>
              ) : (
                <span style={{ color: 'red' }}>- Auction Ended -</span>
              )}
            </div>
          </p>
        </div>
      )
    }
    return <div></div>
  }

  const renderBidJSX = () => {
    if (!isAuctionEnded) {
      return (
        <div style={{ width: '100%' }}>
          <div className="btn-bid">
            <button
              onClick={() => handleBid()}
              disabled={
                !bidPrice ||
                bidPrice < minBidPrice ||
                !tokenInfo?.inited ||
                currentUnixTime < tokenInfo?.auction_info?.start_at ||
                currentUnixTime > tokenInfo?.auction_info?.end_at ||
                isBidding ||
                isBuying
              }
            >
              {isBidding ? 'BIDDING...' : `入札する - ${bidPrice} ETH - Bid Now`}
            </button>

            <button className="btn-bar" onClick={() => setIsOpenListBid(!isOpenListBid)}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          {tokenInfo?.auction_info?.highest_bid?.value <= tokenInfo?.sale_info?.price?.value * 0.9 && (
            <div>
              <div className="or">
                <span>OR</span>
              </div>
              <button className="btn-buy" disabled={!tokenInfo?.inited || isBuying || isBidding} onClick={() => handleBuy()}>
                {isBuying ? 'BUYING...' : `今すぐ購入する - ${tokenInfo?.sale_info?.price?.value || 10} ETH - Buy Now`}
              </button>
            </div>
          )}
        </div>
      )
    }

    return (
      <>
        {totalBidHistories > 0 && (
          <div>
            <p className="fw-700">Highest Bid: {tokenInfo?.auction_info?.highest_bid?.value || 0.1} ETH</p>
            <p className="fw-700">
              Winner:{' '}
              <CopyToClipboard text={tokenInfo?.auction_info?.highest_bidder} onCopy={handleCopy}>
                <span className="cursor-pointer highlight-link">{formatAccountDisplay(tokenInfo?.auction_info?.highest_bidder)}</span>
              </CopyToClipboard>
            </p>
          </div>
        )}
        {totalBidHistories === 0 && (
          <div style={{ width: '100%' }}>
            <button className="btn-buy" disabled={!tokenInfo?.inited || isBuying || isBidding} onClick={() => handleBuy()}>
              {isBuying ? 'BUYING...' : `Buy Now - ${tokenInfo?.sale_info?.price?.value || 10}ETH`}
            </button>
          </div>
        )}
      </>
    )
  }

  return (
    <div className="osamu-chris">
      <div className="container">
        <div className="content">
          <img src="/images/osamu-chris/fb-image.png" alt="" className="fb-image" />
          <div className="nft-detail">
            <div className="left-thumbnail">
              <div className="thumbnail">
                <div className="display"></div>
              </div>
            </div>
            <div className="right-detail">
              <div>
                <div>
                  <div className="title">SIMBA (#X1-0001)</div>
                  <p className="artist">CHRIS</p>
                </div>
                <p>
                  KINGSのマスコット、シンバ。チームのキャプテンも務める。
                  <br />
                  <br />
                  SIMBA is the mascot for KINGS. Also, captain of the team.
                </p>
              </div>
              {renderAuctionInfo()}
              {!isEmpty(tokenInfo) ? (
                <>
                  {account ? (
                    <>
                      {tokenInfo?.inited === false ? (
                        <div style={{ width: '100%' }}>
                          <a
                            href={`https://opensea.io/assets/ethereum/${APP_ENVIRONMENTS.HDKN_CONTRACT_ADDRESS}/0`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="highlight-link"
                          >
                            Buy on Opensea
                          </a>
                          <br />
                          <br />
                          <button disabled>SOLD OUT</button>
                        </div>
                      ) : (
                        renderBidJSX()
                      )}
                    </>
                  ) : (
                    <button onClick={() => connectWallet({ account, chainId, activate, error })}>Connect Wallet</button>
                  )}
                </>
              ) : (
                <Skeleton active />
              )}
            </div>
          </div>
          <ListBidModal isOpen={isOpenListBid} onCancel={() => setIsOpenListBid(!isOpenListBid)} totalBidHistories={totalBidHistories} />
          {/* end nft detail */}
          <div className="video">
            <iframe src="https://www.youtube.com/embed/oFAMgKL3bBg?playlist=oFAMgKL3bBg&loop=1&showinfo=0&ecver=2"></iframe>
            {/* <video src='https://www.youtube.com/embed/oFAMgKL3bBg'></video> */}
          </div>

          <div className="about-chris">
            <div className="title">CHRIS (クリス)</div>
            <br />
            <p>
              サンフランシスコ生まれ、東京育ち。自身がコレクションするシールやカード、ゲーム、漫画、雑誌など、膨大なアーカイブの中から作家独自の世界観でミックスし、作品へと昇華する。
              <br />
              2022年よりトレーディングカードをモチーフにした「FIST OF BASEBALL」を発表。
              <br />
              <br />
              Born in San Francisco and raised in Tokyo. He mixes his vast collection of stickers, cards, video games, manga, and magazines with his
              unique world view and elevates them into his artworks. <br /> Since 2022, he has been presenting “FIST OF BASEBALL,” a new series of
              works inspired by trading cards.
            </p>
            <div className="social">
              <a href={SOCIAL_LINKS.instagram} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href={SOCIAL_LINKS.twitter} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
          </div>
          {/* end about chris */}

          <PastExhibitions />
          <SubscribeFOB />
          <FirstOfBaseball />
          <FAQ />

          <div className="presented-by">
            <div className="title mb-1">PRESENTED BY</div>
            <div className="logo">
              <img src="/images/osamu-chris/klktn.png" alt="" />
              {/* <img src="/images/osamu-chris/hdkn-logo.png" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
