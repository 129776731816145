import RequestEnum from './Request.enum'
import { AuthRequest } from './auth/AuthRequest'
import { HDKNRequest } from './hdkn/hdknRequest'

// eslint-disable-next-line no-unused-vars
const RequestMap = {
  [RequestEnum.AuthRequest]: AuthRequest,
  [RequestEnum.HDKNRequest]: HDKNRequest
}

const instances: any = {}

export class RequestFactory {
  static getRequest<T>(className: RequestEnum): T {
    const RequestClass = RequestMap[className]
    if (!RequestClass) {
      throw new Error(`Invalid request class name: ${className}`)
    }

    let requestInstance = instances[className]
    if (!requestInstance) {
      requestInstance = new RequestClass()
      instances[className] = requestInstance
    }
    return requestInstance
  }
}

export { RequestEnum }
export * from './auth/AuthRequest'
export * from './hdkn/hdknRequest'
