import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../configs'

export const HomePage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(ROUTES.dropsChris.path)
  }, [])

  return <></>
}
