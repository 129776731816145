export const LOGIN = 'LOGIN'
export const LEAVE_LOGIN = 'LEAVE_LOGIN'
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN'
export const LOGOUT = 'LOGOUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const LEAVE_FORGOT_PASSWORD = 'LEAVE_FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const LEAVE_RESET_PASSWORD = 'LEAVE_RESET_PASSWORD'
export const SIGN_UP = 'SIGN_UP'
export const LEAVE_SIGN_UP = 'LEAVE_SIGN_UP'
