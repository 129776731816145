import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import './index.sass'

export const CountDownTime = ({ endTime, counterEnd }: CountDownTimeProps) => {
  const [isHidden, setIsHidden] = useState(false)
  const [duration, setDuration] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0
  })
  const [intervalId, setIntervalId] = useState<any>(null)

  useEffect(() => {
    const _endTime = moment.unix(endTime)
    const counter = () => {
      const currentTime = moment.utc()

      if (endTime) {
        const diffDuration = moment.duration(_endTime.diff(currentTime))
        setDuration({
          days: diffDuration.days(),
          hours: diffDuration.hours(),
          mins: diffDuration.minutes(),
          secs: diffDuration.seconds()
        })
      }

      if (!_endTime.isAfter(currentTime)) {
        intervalId && clearInterval(intervalId)
        setIsHidden(true)
        counterEnd()
      }
    }
    if (intervalId === null) {
      counter()
    }
    const interval = setInterval(() => {
      counter()
    }, 1000)

    setIntervalId(interval)

    return intervalId && clearInterval(intervalId)
  }, [endTime])

  return useMemo(
    () => (
      <>
        {!isHidden && (
          <div className="countdown-time">
            <div className="time-wrapper">
              <div className="item">
                {duration.days > 0 && <span>{`${duration.days}`} days</span>}{' '}
                {(duration.days > 0 || duration.hours > 0) && <span>{`${duration.hours}`} hours</span>}{' '}
                {(duration.days > 0 || duration.hours > 0 || duration.mins > 0) && <span>{`${duration.mins}`} minutes</span>}{' '}
                {duration.days <= 0 && <span>{`${duration.secs}`} seconds</span>}
              </div>
            </div>
          </div>
        )}
      </>
    ),
    [duration, isHidden]
  )
}

interface CountDownTimeProps {
  endTime: number
  counterEnd: () => void
}
