export const QUERY_CONTRACT_ABI = [
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'contractAddress', type: 'address' },
          { internalType: 'bytes', name: 'bytesCaller', type: 'bytes' }
        ],
        internalType: 'struct Query.CallMapper[]',
        name: '_callers',
        type: 'tuple[]'
      }
    ],
    name: 'callContractsWith',
    outputs: [
      { internalType: 'bool[]', name: '', type: 'bool[]' },
      { internalType: 'bytes[]', name: '', type: 'bytes[]' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_contract', type: 'address' },
      { internalType: 'address', name: '_account', type: 'address' }
    ],
    name: 'getTokensByAccount',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function'
  }
]
