import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/shared/Button'
import { ROUTES } from '../../../configs'
import './index.sass'

export const GlobalNotFound = () => {
  const navigate = useNavigate()
  return (
    <div className="page-404">
      <p className="text-page-not-found">404 | Page Not Found</p>
      <Button className="background-green" clickFunction={() => navigate(ROUTES.home.path)}>
        Go back
      </Button>
    </div>
  )
}
