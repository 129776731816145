import React, { useEffect, useState } from 'react'
import { message, Modal, Space, Spin, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import './index.sass'
import { HDKNRequest, RequestEnum, RequestFactory } from '../../../../requests'
import { map } from 'lodash'
import moment from 'moment'

interface DataType {
  key: string
  timestamp: number
  bidder: string
  amount: string
}

const DEFAULT_PAGE_INDEX = 1
const DEFAULT_PAGE_SIZE = 5

export const ListBidModal = ({ isOpen = false, onCancel, totalBidHistories }: ListBidModalProps) => {
  const hdknRequest = RequestFactory.getRequest<HDKNRequest>(RequestEnum.HDKNRequest)
  const [bidHistories, setBidHistories] = useState<any>([])
  const [loadingData, setLoadingData] = useState(true)

  const columns: ColumnsType<DataType> = [
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: timestamp => moment.unix(timestamp).local().format('DD MMM YYYY, HH:mm')
    },
    {
      title: 'Account',
      dataIndex: 'bidder',
      key: 'bidder',
      render: bidder => (
        <a href="javascript:void(0)" onClick={() => handleCopy(bidder)} className="account">{`${bidder?.substr(0, 6)}...${bidder?.substr(-6, 6)}`}</a>
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: amount => `${amount} ETH`
    }
  ]

  useEffect(() => {
    getBidHistories()
  }, [totalBidHistories])

  const handleCopy = (account: string) => {
    navigator.clipboard.writeText(account)
    message.success('Copied to clipboard')
  }

  const getBidHistories = async (pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE) => {
    if (!totalBidHistories) {
      setLoadingData(false)
      return
    }

    setLoadingData(true)
    const data = await hdknRequest.getBidHistoriesByPaginate(0, totalBidHistories, { page_index: pageIndex, page_size: pageSize })
    setBidHistories(map(data?.items, (el, idx) => ({ ...el, key: idx })))
    setLoadingData(false)
  }

  return (
    <Modal className="list-bid-modal" title="入札履歴  Bid History" visible={isOpen} onCancel={onCancel}>
      <Table
        columns={columns}
        dataSource={bidHistories}
        loading={{ indicator: <Spin size="large" />, spinning: loadingData }}
        pagination={{
          defaultCurrent: DEFAULT_PAGE_INDEX,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          total: totalBidHistories,
          onChange: (page, pageSize) => {
            getBidHistories(page, pageSize)
          }
        }}
      />
    </Modal>
  )
}

interface ListBidModalProps {
  isOpen: boolean
  onCancel: () => void
  totalBidHistories: number
}
