import { createReducer } from '@reduxjs/toolkit'
import { IUserDto } from '../../types/User'
import { leaveLoginPage, login, getAccessToken, signUp, leaveSignUp, logout } from './action'

interface ICommonState {
  token: string | undefined
  userKey: string | undefined
  auth: IUserDto | undefined

  errors: {
    login: any
    forgotPassword: any
    resetPassword: any
    verifyUserPin: any
    getCodeSignUp: any
    verifyCodeSignUp: any
    signUp: any
  }
}

const initialState: ICommonState = {
  token: undefined,
  userKey: undefined,
  auth: undefined,

  errors: {
    login: undefined,
    forgotPassword: undefined,
    resetPassword: undefined,
    verifyUserPin: undefined,
    getCodeSignUp: undefined,
    verifyCodeSignUp: undefined,
    signUp: undefined
  }
}

export default createReducer(initialState, builder => {
  builder
    // login handle
    .addCase(login.fulfilled, (state, action) => {
      const [userKey, token] = action.payload
      return {
        ...state,
        userKey,
        token
      }
    })
    .addCase(login.rejected, (state, action) => {
      state.errors.login = (action.payload as any)?.error
    })
    .addCase(leaveLoginPage, state => {
      state.errors.login = undefined
    })

    // logout handler
    .addCase(logout.fulfilled, () => {
      return initialState
    })

    // get access token handler
    .addCase(getAccessToken.fulfilled, (state, action) => {
      const user = action.payload
      return {
        ...state,
        auth: user
      }
    })

    // sign up
    .addCase(signUp.rejected, (state, action) => {
      state.errors.signUp = (action.payload as any)?.error
    })
    .addCase(leaveSignUp, state => {
      state.errors.signUp = undefined
    })
})
