export const ROUTES = {
  home: {
    path: '/',
    title: 'Home Page'
  },
  dropsChris: {
    path: '/drops/chris',
    title: 'Osamu Chris'
  }
}

export const AUTH_ROUTES = {
  login: {
    path: '/login',
    title: 'Login'
  },
  signup: {
    path: '/signup',
    title: 'Signup'
  },
  forgotPassword: {
    path: '/forgot-password',
    title: 'Forgot Password'
  },
  resetPassword: {
    path: '/reset-password/:email',
    title: 'Reset Password'
  },
  logout: {
    path: '/logout',
    title: 'Logout'
  }
}
