import { Middleware } from 'redux'
import { LOGIN_FULLFILLED, LOGIN_REJECTED, LOGOUT_FULLFILLED, SIGN_UP_FULLFILLED } from './../common/action'
import { APP_ENVIRONMENTS } from './../../configs/env'

const accessTokenMiddleware: Middleware = () => next => action => {
  switch (action.type) {
    case SIGN_UP_FULLFILLED:
    case LOGIN_FULLFILLED: {
      const [user, token, refreshToken] = action.payload
      localStorage.setItem(APP_ENVIRONMENTS.REACT_APP_JWT_KEY, token)
      localStorage.setItem(APP_ENVIRONMENTS.AUTH, JSON.stringify(user))
      localStorage.setItem(APP_ENVIRONMENTS.REACT_APP_REFRESH_TOKEN, refreshToken)
      break
    }

    case LOGIN_REJECTED:
    case LOGOUT_FULLFILLED: {
      localStorage.removeItem(APP_ENVIRONMENTS.REACT_APP_JWT_KEY)
      localStorage.removeItem(APP_ENVIRONMENTS.AUTH)
      localStorage.removeItem(APP_ENVIRONMENTS.REACT_APP_REFRESH_TOKEN)
      break
    }
  }

  next(action)
}

export default accessTokenMiddleware
