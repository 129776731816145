import { message, notification } from 'antd'
import { APP_ENVIRONMENTS, COPY, NETWORK_DATA, NOTIFICATION_MESSAGE } from '../configs'
import { isMobile, isTablet } from 'react-device-detect'
import { injected } from '../connectors'
import { get } from 'lodash'
import { IConnectWalletParams } from '../types/Helpers'
import { ethers } from 'ethers'

declare global {
  interface Window {
    ethereum: any
  }
}

export const connectWallet = async ({ account, chainId, activate, error }: IConnectWalletParams) => {
  const { ethereum } = window
  const network = get(NETWORK_DATA, APP_ENVIRONMENTS.DEFAULT_NETWORK)

  if (isTablet || isMobile) {
    if (!(ethereum && ethereum.isMetaMask)) {
      return window.open(APP_ENVIRONMENTS.METAMASK_DEEP_LINK)
    }
  }

  if (account) {
    return
  }

  if (!ethereum) {
    return notification.error({
      message: 'Error',
      description: NOTIFICATION_MESSAGE.metamask.error_metamask_plugin
    })
  }

  if (error) {
    // if (error?.code === -32002) {
    //   // return toast.error(error.message)
    //   return notification.error({
    //     message: 'Error',
    //     description: error.message
    //   })
    // }

    if (chainId !== network.chainId) {
      return notification.error({
        message: 'Error',
        description: network.messageErr
      })
    }
  }

  // // If the provider is not detected, we will check this case
  // const ethChainId = await window.ethereum.request({
  //   method: 'eth_chainId'
  // })

  // if (ethChainId !== network?.ethChainId) {
  //   return notification.error({
  //     message: 'Error',
  //     description: network?.messageErr
  //   })
  // }

  try {
    activate(injected)
  } catch (error) {
    console.log('error', error)
  }
}

export const handleCopy = () => {
  message.success(COPY.success)
}

export const formatAccountDisplay = (account: string) => {
  if (account) {
    return `${account?.substr(0, 6)}...${account?.substr(-6, 6)}`
  }
  return account
}

export const formatTransactionHashDisplay = (transactionHash: string) => {
  if (transactionHash) {
    return `${transactionHash?.substr(0, 10)}...${transactionHash?.substr(-10, 10)}`
  }
  return transactionHash
}

export const getProviderByEtherJs = (metamask?: any) => {
  if (metamask) return new ethers.providers.Web3Provider(metamask)
  return ethers.getDefaultProvider(APP_ENVIRONMENTS.ETHEREUM_PROVIDER_URL)
}

export const connectQueryContract = (metamask?: any) => {
  const provider = getProviderByEtherJs(metamask)

  return new ethers.Contract(
    APP_ENVIRONMENTS.QUERY_CONTRACT_ADDRESS, //
    APP_ENVIRONMENTS.QUERY_CONTRACT_ABI,
    provider
  )
}
