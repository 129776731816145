import { ethers } from 'ethers'
import { map, reverse } from 'lodash'
import { APP_ENVIRONMENTS } from '../../configs'
import { connectQueryContract } from '../../utils/helpers'
import { BaseRequest } from '../BaseRequest'

export class HDKNRequest extends BaseRequest {
  async getTokenInfo(tokenId: number) {
    const queryContract = connectQueryContract()

    const IHdkn = new ethers.utils.Interface(APP_ENVIRONMENTS.HDKN_CONTRACT_ABI)
    const functionSignatures = [
      {
        contractAddress: APP_ENVIRONMENTS.HDKN_CONTRACT_ADDRESS,
        bytesCaller: IHdkn.encodeFunctionData('tokens', [tokenId])
      },
      {
        contractAddress: APP_ENVIRONMENTS.HDKN_CONTRACT_ADDRESS,
        bytesCaller: IHdkn.encodeFunctionData('getAuctionHistoriesLength', [tokenId])
      }
    ]

    const res = await queryContract.callContractsWith(functionSignatures)

    const tokenInfo = IHdkn.decodeFunctionResult('tokens', res[1][0])
    return {
      token_info: {
        inited: tokenInfo?.inited,
        sale_info: {
          buyable: tokenInfo?.saleInfo.buyable,
          price: {
            value: Number(ethers.utils.formatEther(tokenInfo?.saleInfo?.price)),
            unit: 'ether'
          }
        },
        auction_info: {
          active: tokenInfo?.auctionInfo?.active,
          start_at: Number(tokenInfo?.auctionInfo?.startAt),
          end_at: Number(tokenInfo?.auctionInfo?.endAt),
          window_time: Number(tokenInfo?.auctionInfo?.windowTime),
          reserve_price: {
            value: Number(ethers.utils.formatEther(tokenInfo?.auctionInfo?.reservePrice)),
            unit: 'ether'
          },
          minimal_bid_gap: {
            value: Number(ethers.utils.formatEther(tokenInfo?.auctionInfo?.minimalBidGap)),
            unit: 'ether'
          },
          highest_bidder: tokenInfo?.auctionInfo?.highestBidder,
          highest_bid: {
            value: Number(ethers.utils.formatEther(tokenInfo?.auctionInfo?.highestBid)),
            unit: 'ether'
          }
        }
      },
      total_bid_histories: Number(IHdkn.decodeFunctionResult('getAuctionHistoriesLength', res[1][1]))
    }
  }

  async getBidHistoriesByPaginate(
    tokenId: number,
    totalItems: number,
    paginate: {
      page_index: number
      page_size: number
    } = {
      page_index: APP_ENVIRONMENTS.PAGE_INDEX,
      page_size: APP_ENVIRONMENTS.PAGE_SIZE
    }
  ) {
    const { page_index, page_size } = paginate

    const currentPage = Math.max(page_index - 1, 0)
    const offsetFrom = currentPage * page_size
    const offsetTo = page_index * page_size

    const from = Math.max(totalItems - offsetTo, 0)
    const to = Math.max(totalItems - offsetFrom, 0)

    const queryContract = connectQueryContract()

    const IHdkn = new ethers.utils.Interface(APP_ENVIRONMENTS.HDKN_CONTRACT_ABI)
    const functionSignatures = [
      {
        contractAddress: APP_ENVIRONMENTS.HDKN_CONTRACT_ADDRESS,
        bytesCaller: IHdkn.encodeFunctionData('getAuctionHistories', [tokenId, from, to])
      },
      {
        contractAddress: APP_ENVIRONMENTS.HDKN_CONTRACT_ADDRESS,
        bytesCaller: IHdkn.encodeFunctionData('getAuctionHistoriesLength', [tokenId])
      }
    ]

    const res = await queryContract.callContractsWith(functionSignatures)

    const items = map(IHdkn.decodeFunctionResult('getAuctionHistories', res[1][0])?.items, el => {
      return {
        timestamp: Number(el?.timestamp),
        bidder: String(el?.bidder),
        amount: ethers.utils.formatEther(el?.amount)
      }
    })

    return {
      total_bid_histories: Number(IHdkn.decodeFunctionResult('getAuctionHistoriesLength', res[1][1])),
      items: reverse(items)
    }
  }
}
