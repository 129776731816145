export const TIME_FORMAT = {
  DD_MMM_YYYY: 'DD-MMM-YYYY',
  YYYY_MMM_DD: 'YYYY-MMM-DD',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD HH:mm',
  YYYY_MMM_DD_HH_MM: 'YYYY-MMM-DD HH:mm',
  HH_MM_DD_MM_YYYY: 'HH:mm DD-MM-YYYY',
  UTC_OFFSET: '+00:00',
  INPUT_FORMAT: 'YYYY-MMM-DD  HH:mm'
}

export const QUERY_KEYS = {
  email: 'email',
  searchKey: 'searchKey'
}

export const NOTIFICATION_MESSAGE = {
  auth: {
    register: 'Register Successfully',
    resetPassword: 'Reset password successfully',
    editDetails: 'Update Successfully!',
    setTOTP: 'Setup TOTP Successfully',
    unauthorized: 'Please login to continue',
    changePin: 'Change PIN successfully',
    changePassword: 'Change password successfully',
    changeAvatar: 'Change avatar successfully'
  },
  productNFT: {
    importNFTSuccess: 'Import NFT Successfully',
    exportNFTSuccess: 'Export NFT Successfully',
    disableFilter: 'You are using the main search. Please remove the keyword to continue with filter'
  },
  metamask: {
    error_metamask_plugin: 'Non-Ethereum browser detected. Please install MetaMask plugin',
    error_ethereum_main_network: 'Please connect to Ethereum Main Network',
    error_ethereum_test_network: 'Please connect to Rinkeby Test Network',
    unknown_error: 'An error has occurred, please try again',
    error_balance: 'Your balance is not enough'
  },
  transactions: {
    depositSuccess: 'Deposit Successfully',
    withdrawSuccess: 'Withdrawal request has been sent successfully'
  }
}

export const USER = {
  buyXCURSuccess: 'Buy XCUR successfully',
  buyNFTSuccess: 'Buy NFT successfully',
  bidNFTSuccess: 'Bid NFT successfully',
  success: 'Successfully'
}

export const PAGE_SIZE = {
  featuredCollections: 5,
  trending: 31,
  featured: 36,
  weekly: 32,
  importedNFT: 5
}

export const COPY = {
  success: 'Copied to clipboard'
}

export const NETWORK_DATA = {
  mainnet: {
    chainId: 1,
    ethChainId: '0x1',
    messageErr: NOTIFICATION_MESSAGE.metamask.error_ethereum_main_network
  },
  testnet: {
    chainId: 4,
    ethChainId: '0x4',
    messageErr: NOTIFICATION_MESSAGE.metamask.error_ethereum_test_network
  }
}

export const SOCIAL_LINKS = {
  instagram: 'https://www.instagram.com/chrrris3',
  twitter: 'https://twitter.com/fistofbaseball'
}
