import React from 'react'
import { ConfigProvider, message, notification } from 'antd'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { CommonLayout } from './layouts'
import { Login, Signup } from './pages/Auth'

import './styles/index.sass'

import { GlobalNotFound } from './pages/Global'
import { HomePage } from './pages/Home'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { ROUTES } from './configs'
import { OsamuChris } from './pages/Drops/OsamuChris'

const getLibrary = (provider: any) => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

notification.config({
  duration: 2,
  maxCount: 2
})

message.config({
  duration: 1,
  maxCount: 2
})

export const App = () => {
  return (
    <ConfigProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <Routes>
            <Route path={ROUTES.home.path} element={<CommonLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path={ROUTES.dropsChris.path} element={<OsamuChris />} />
              <Route path="*" element={<GlobalNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Web3ReactProvider>
    </ConfigProvider>
  )
}
