import axios, { AxiosRequestConfig } from 'axios'
import { APP_ENVIRONMENTS } from '../configs'

/**
 * App Variables
 */
const { API_ROOT_URL, REACT_APP_JWT_KEY } = APP_ENVIRONMENTS

const client = axios.create({
  baseURL: API_ROOT_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})
client.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = window.localStorage.getItem(REACT_APP_JWT_KEY)
    const requestConfig = {
      ...config
    }
    if (token) {
      requestConfig.headers = {
        Authorization: `Bearer ${token}`
      }
    }
    return requestConfig
  },
  err => {
    return Promise.reject(err)
  }
)

export class BaseRequest {
  async get(url: string, params = {}) {
    try {
      const config = {
        params
      }

      const response = await client.get(url, config)
      return this._responseHandler(response)
    } catch (error) {
      return this._errorHandler(error)
    }
  }

  async put(url: string, data = {}) {
    try {
      const response = await client.put(url, data)
      return this._responseHandler(response)
    } catch (error) {
      return this._errorHandler(error)
    }
  }

  async post(url: string, data = {}, opts = {}) {
    try {
      const response = await client.post(url, data, opts)
      return this._responseHandler(response)
    } catch (error) {
      return this._errorHandler(error)
    }
  }

  async del(url: string, data = {}) {
    try {
      const response = await client.delete(url, {
        data
      })
      return this._responseHandler(response)
    } catch (error) {
      return this._errorHandler(error)
    }
  }

  async _responseHandler(response: any) {
    return response.data
  }

  _errorHandler = async (err: any) => {
    console.error('[BaseRequest] get error :', err)
    // eslint-disable-next-line no-throw-literal
    throw {
      error: {
        status: err.response?.status,
        message: err.response?.data?.error?.message
      }
    }
  }
}

export type OptionsType = {
  page_size: number
  page_index: number
}
