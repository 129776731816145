import { AuthRequest } from './../../requests/auth/AuthRequest'
import { RequestFactory } from './../../requests/index'
import { LEAVE_LOGIN, LOGIN, GET_ACCESS_TOKEN, SIGN_UP, LEAVE_SIGN_UP, LOGOUT } from './action.type'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { APP_ENVIRONMENTS } from '../../configs'
import RequestEnum from '../../requests/Request.enum'
import { IForgotPassword, ILoginData, IResetPasswordParams, ISignupData } from '../../types/Auth'
import { IForgotPasswordDto, IResetPasswordDto, ISignUpDto, ISignUpGetCode, IUserDto } from '../../types/User'

export const login = createAsyncThunk<
  [string, string],
  {
    params: ILoginData
    // eslint-disable-next-line no-unused-vars
    callback: (isSuccess: boolean) => any
  }
>(LOGIN, async ({ params, callback }, { rejectWithValue }) => {
  try {
    const response = await RequestFactory.getRequest<AuthRequest>(RequestEnum.AuthRequest).login(params)
    callback(true)

    return [response?.key || '', response?.token || '']
  } catch (err) {
    callback(false)
    return rejectWithValue(err)
  }
})

export const leaveLoginPage = createAction(LEAVE_LOGIN)

export const getAccessToken = createAsyncThunk<
  IUserDto,
  {
    // eslint-disable-next-line no-unused-vars
    callback?: (...body: any) => any
  },
  {
    rejectValue: null
  }
>(GET_ACCESS_TOKEN, async ({ callback }, { rejectWithValue }) => {
  try {
    const token = window.localStorage.getItem(APP_ENVIRONMENTS.REACT_APP_JWT_KEY)
    if (token) {
      const response = await RequestFactory.getRequest<AuthRequest>(RequestEnum.AuthRequest).getUserByJWTToken()
      return response || {}
    }
    return rejectWithValue(null)
  } catch (e) {
    return rejectWithValue(null)
  } finally {
    callback && callback()
  }
})

export const logout = createAsyncThunk<
  void,
  {
    // eslint-disable-next-line no-unused-vars
    callback?: (...body: any) => any
  }
>(LOGOUT, async ({ callback }, { rejectWithValue }) => {
  try {
    RequestFactory.getRequest<AuthRequest>(RequestEnum.AuthRequest).logout()
    return
  } catch (err) {
    return rejectWithValue(err)
  } finally {
    callback && callback()
  }
})

export const LOGIN_FULLFILLED = login.fulfilled.toString()
export const LOGIN_REJECTED = login.rejected.toString()
export const LOGOUT_FULLFILLED = logout.fulfilled.toString()

export const signUp = createAsyncThunk<
  ISignUpDto,
  {
    params: ISignupData
    // eslint-disable-next-line no-unused-vars
    callback: (isSuccess: boolean) => any
  }
>(SIGN_UP, async ({ params, callback }, { rejectWithValue }) => {
  try {
    const response = await RequestFactory.getRequest<AuthRequest>(RequestEnum.AuthRequest).signUp(params)
    callback(true)
    return response
  } catch (err) {
    callback(false)
    return rejectWithValue(err)
  }
})

export const leaveSignUp = createAction(LEAVE_SIGN_UP)

export const SIGN_UP_FULLFILLED = signUp.fulfilled.toString()
