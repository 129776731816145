import React from 'react'
import { Link } from 'react-router-dom'
import { Spin } from 'antd'

import './index.sass'

export default function Button({
  className,
  size = 'normal',
  displayType = 'button',
  buttonType = 'button',
  link = '',
  loading,
  disabled,
  children,
  clickFunction
}: IButtonProps) {
  if (displayType === 'link') {
    return (
      <Link className={`base-button link ${className || ''} ${size || ''}`} to={link}>
        {children}
      </Link>
    )
  }
  return (
    <button className={`base-button ${className || ''} ${size || ''}`} type={buttonType} onClick={clickFunction} disabled={disabled}>
      {loading ? <Spin /> : children}
    </button>
  )
}

interface IButtonProps {
  className?: string
  size?: 'normal' | 'full-width'
  displayType?: 'button' | 'link'
  link?: string
  buttonType?: 'button' | 'submit' | 'reset'
  loading?: boolean
  clickFunction?: () => void
  disabled?: boolean
  children: React.ReactNode
  [key: string]: any
}
