import { configureStore } from '@reduxjs/toolkit'
import commonReducer from './common/reducer'
import accessTokenMiddleware from './middlewares/accessToken.middleware'
import authorization from './middlewares/authorization.middleware'

const store = configureStore({
  reducer: {
    common: commonReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authorization).concat(accessTokenMiddleware)
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
